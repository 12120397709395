import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

// Icons for Investor Resources

import "../components/my_global.scss"
import "../components/responsive-homepage.css"
import "../sass/main.scss"

import logo1 from "../images/news-logos/investopedia-2.png"
import logo2 from "../images/news-logos/investopedia.png"
import logo3 from "../images/news-logos/wealth-management.png"
import logo4 from "../images/news-logos/expertise.png"
import logo5 from "../images/news-logos/expertise-2.png"
import logo6 from "../images/news-logos/channel-futures.png"
import logo7 from "../images/news-logos/marquis.png"
import logo8 from "../images/news-logos/investopedia-3.png"

import LeaderShip from "../images/homepage/icons/leadership.png"
import Responisiblity from "../images/homepage/icons/responsibility.png"
import Performance from "../images/homepage/icons/performance.png"
import Transparency from "../images/homepage/icons/transparency.png"

import Visionary from "../images/homepage/visionary.webp"

import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component"

const AwardsRecognitions = loadable(
  () => pMinDelay(import("../components/AwardsRecognitions")),
  500
)
const CustomArticles = loadable(
  () => pMinDelay(import("../components/CustomArticles")),
  500
)
const CustomContact = loadable(
  () => pMinDelay(import("../components/customContactForm")),
  500
)

const FocusExpertise = loadable(
  () => pMinDelay(import("../components/FocusExpertise")),
  500
)
const WhatWeDo = loadable(
  () => pMinDelay(import("../components/WhatWeDo")),
  500
)
const HeadingScore = loadable(
  () => pMinDelay(import("../components/HeadingScore")),
  500
)
const BannerHero = loadable(
  () => pMinDelay(import("../components/banner-hero")),
  500
)

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const posts = this.props.data.allSanityPost
    const wwd = {
      subtitle: "Visionary",
      title: "INNOVATIVE SOLUTIONS BUILT AROUND YOU",
      description:
        "Bay Street Capital Holdings is a leading provider of investment, advisory, wealth management, and financial planning services. As a fiduciary, we invest on the behalf of our clients with integrity, focus, and careful stewardship of their capital. Our mission is to inspire the next generation of diversified investors through the investment of alternative asset classes with priority on managing total risk and volatility.",
      img: Visionary,
    }
    const awards = [logo1, logo2, logo8, logo3, logo4, logo5, logo6, logo7]

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div className="pages-wraps">
          <BannerHero />
          <HeadingScore />
          <WhatWeDo data={wwd} />
          <FocusExpertise />

          <LazyLoadComponent>
            <section className="our-guiding-principles section_padding_new">
              <div className="container-fluid">
                <h2 className="text-uppercase">Our Guiding Principles</h2>
                <div className="row ogp-row">
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div className="ogp-box">
                      <h4 className="lead text-uppercase">Transparency</h4>
                      <p>
                        We value the trust our clients place in us and uphold
                        the highest standards of integrity, honesty, and
                        transparency.
                      </p>
                      <StaticImage
                        src="../images/homepage/icons/transparency.png"
                        className="img-fluid ogp-img"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "35%",
                        }}
                        loading="lazy"
                        alt="transparency"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div className="ogp-box">
                      <h4 className="lead text-uppercase">Performance</h4>
                      <p className="">
                        We invest with high conviction, through the lens of
                        history, in companies that will outperform, reduce risk,
                        and enhance returns.
                      </p>
                      <StaticImage
                        src="../images/homepage/icons/performance.png"
                        className="img-fluid ogp-img"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "35%",
                        }}
                        loading="lazy"
                        alt="performance"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div className="ogp-box">
                      <h4 className="lead text-uppercase">Responsibility</h4>
                      <p>
                        We are committed to exceptional standards of corporate
                        responsibility as a fiduciary to improve the lives of
                        our clients.
                      </p>
                      <StaticImage
                        src="../images/homepage/icons/responsibility.png"
                        className="img-fluid ogp-img"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "35%",
                        }}
                        loading="lazy"
                        alt="responsibility"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <div className="ogp-box">
                      <h4 className="lead text-uppercase">Leadership</h4>
                      <p>
                        Our leadership team works toward a single purpose: To
                        improve the financial security of our clients and exceed
                        expectations.
                      </p>
                      <StaticImage
                        src="../images/homepage/icons/leadership.png"
                        className="img-fluid ogp-img"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "35%",
                        }}
                        loading="lazy"
                        alt="leadership"
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </section>
          </LazyLoadComponent>

          <AwardsRecognitions hasBG={true} awards={awards} />

          <CustomArticles posts={posts} />

          <CustomContact />
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allSanityPerson(
      filter: { roles: { eq: "Client" } }
      sort: { order: ASC, fields: bydepartmentOrder }
    ) {
      edges {
        node {
          bydepartmentOrder
          name
          slug {
            current
          }
          roles
          position
          image {
            asset {
              gatsbyImageData(formats: [WEBP, AVIF])
            }
          }
        }
      }
    }
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { language: { eq: "en" } }
    ) {
      edges {
        node {
          id
          title
          language
          slug {
            current
          }
          featured
          mainImage {
            asset {
              gatsbyImageData
            }
            alt
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          excerpt
          videoUrl
          categories {
            id
            title
          }
          authors {
            name
            image {
              asset {
                gatsbyImageData(width: 50, height: 50)
              }
            }
          }
        }
      }
    }
  }
`
